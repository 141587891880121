import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { createBrowserRouter, RouterProvider } from "react-router-dom";

import { useGetCustomerQuery } from "lib/slices/customerServiceSlice";
import { setUser } from "lib/slices/userSlice";
import { IRootState } from "lib/store";

import { PageError } from "pages/PageError";

type Props = {
  children: JSX.Element | JSX.Element[];
};

export function AuthProvider({ children }: Props) {
  const params = new URLSearchParams(
    decodeURIComponent(window.location.search),
  );
  const customerId = params.get("customerId");

  const dispatch = useDispatch();
  const user = useSelector((state: IRootState) => state.user.user);

  const { data } = useGetCustomerQuery(customerId || (user?.id as string), {
    skip: !customerId || (!!user && user.id === customerId),
  });

  if (data) {
    dispatch(setUser(data));
  }

  const { t } = useTranslation();

  if (!user) {
    const router = createBrowserRouter([
      {
        path: "*",
        element: (
          <PageError
            title={t("pages.errors.401.title")}
            message={t("pages.errors.401.message")}
          />
        ),
      },
    ]);

    return <RouterProvider router={router} />;
  }

  return children;
}
