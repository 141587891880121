import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Outlet, ScrollRestoration, useSearchParams } from "react-router-dom";

import { Footer } from "components/Footer";
import { Navbar } from "components/Navbar";
import { SkeletonGroup } from "components/SkeletonGroup/SkeletonGroup";

import { appStorage } from "lib/localStorage";
import { setLanguage } from "lib/slices/languageSlice";
import { setTheme } from "lib/slices/themeSlice";
import { IRootState } from "lib/store";

import i18n from "utils/i18n";

import styles from "./LayoutApp.module.scss";

const THEME_TO_NAME: Record<string, string> = {
  epark: "ePark",
  redcross: "Red Cross",
  allente: "Allente",
  dentolo: "Dentolo",
  tele2: "Tele2",
  unicef: "UNICEF",
  wave: "Wave",
};

export function LayoutApp() {
  const [searchParams] = useSearchParams();

  const dispatch = useDispatch();
  const theme = useSelector((state: IRootState) => state.theme.theme);
  const { t } = useTranslation();

  const [companyInfo, setCompanyInfo] = useState({
    name: "",
    logoSrc: "",
    bannerSrc: {
      forDesktop: "",
      forMobile: "",
    },
    links: [
      {
        title: t("components.footer.website"),
        href: "#",
        external: true,
      },
      {
        title: t("components.footer.support"),
        href: "/support",
      },
      {
        title: t("components.footer.account"),
        href: "/account",
      },
    ],
    socials: [
      {
        title: "Instagram",
        href: "#",
        external: true,
      },
      {
        title: "Facebook",
        href: "#",
        external: true,
      },
      {
        title: "Twitter",
        href: "#",
        external: true,
      },
    ],
  });

  const [clientsAssets, setClientsAssets] = useState<{
    loading: boolean;
    error: string | null;
  }>({
    loading: false,
    error: null,
  });

  useEffect(() => {
    const themeQuery = searchParams.get("c");
    dispatch(setTheme(themeQuery));
  }, [searchParams]);

  useEffect(() => {
    if (THEME_TO_NAME[theme as string]) {
      setCompanyInfo((prev) => ({
        ...prev,
        name: THEME_TO_NAME[theme as string] ?? (theme as string),
        logoSrc: `/assets/${theme}/logo.png`,
        bannerSrc: {
          forDesktop: `/assets/${theme}/image-block-desktop.webp`,
          forMobile: `/assets/${theme}/image-block-mobile.webp`,
        },
      }));
    } else {
      setClientsAssets({
        loading: true,
        error: null,
      });

      const getUser = appStorage.getItem<Record<string, string>>("user");

      if (getUser) {
        fetch(
          process.env.REACT_APP_API_CLIENTS_URL! +
            `/clients/${getUser.clientId}`,
        )
          .then((res) => {
            if (!res.ok) {
              setClientsAssets({
                loading: false,
                error: res.statusText,
              });
            }

            return res.json();
          })
          .then((data) => {
            if(data.data) {
              const html = document.querySelector("html");
              html?.setAttribute("data-theme", data.data["name"]);

              setCompanyInfo((prev) => ({
                ...prev,
                bannerSrc: data.data["banner_uri"]
                  ? {
                    forDesktop: data.data["banner_uri"],
                    forMobile: data.data["banner_uri"],
                  }
                  : {
                    forDesktop: "/assets/wave/image-block-desktop.webp",
                    forMobile: "/assets/wave/image-block-mobile.webp",
                  },
                logoSrc: data.data["logo_uri"]
                  ? data.data["logo_uri"]
                  : "/assets/wave/logo.png",
                name: data.data["name"],
              }));
            }

            setClientsAssets({
              loading: false,
              error: null,
            });
          });
      } else {
        dispatch(setTheme("wave"));
      }
    }
  }, [theme]);

  // This is here to update the html lang attribute on first load
  useEffect(() => {
    dispatch(setLanguage(i18n.language));

    dispatch(setTheme(localStorage.getItem("theme")));
  }, []);

  return (
    <>
      {clientsAssets.loading ? (
        <SkeletonGroup
          options={[
            {
              width: "100%",
              height: "40vh",
              borderRadius: "15px",
            },
          ]}
        />
      ) : (
        <Navbar
          companyName={companyInfo.name}
          logoSrc={companyInfo.logoSrc}
          bannerSrc={companyInfo.bannerSrc}
        />
      )}
      <main className={styles.main}>
        <Outlet />
      </main>
      <Footer
        companyName={companyInfo.name}
        logoSrc={companyInfo.logoSrc}
        appearance={"dark"}
        links={companyInfo.links}
        socials={companyInfo.socials}
      />
      <ScrollRestoration />
    </>
  );
}
