import { createSlice } from "@reduxjs/toolkit";

export const themeSlice = createSlice({
  name: "theme",
  initialState: {
    theme: localStorage.getItem("theme") || "",
  },
  reducers: {
    setTheme: (state, { payload: theme }) => {
      if (!theme) {
        return;
      }

      const html = document.querySelector("html");
      html?.setAttribute("data-theme", theme);
      localStorage.setItem("theme", theme);
      state.theme = theme;
    },
  },
});

export const { setTheme } = themeSlice.actions;

export default themeSlice.reducer;
