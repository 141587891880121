import { useState } from "react";
import { isMobile } from "react-device-detect";
import { useTranslation } from "react-i18next";
import { useParams, useSearchParams } from "react-router-dom";

import { InvoicePaymentCard } from "features/invoice";
import { LayoutPage } from "layouts/LayoutPage";

import { PageHeader } from "components/PageHeader";

import { useHead } from "lib/hooks/useHead";
import { useGetInvoiceQuery } from "lib/slices/invoiceServiceSlice";
import { useInitSwishPaymentMutation } from "lib/slices/paymentServiceSlice";
import { SwishPaymentRequest } from "lib/types";

import styles from "./PagePayment.module.scss";
import { PagePaymentStatus } from "./PagePaymentStatus";

const sleep = (ms: number) => new Promise((r) => setTimeout(r, ms));

export function PagePayment() {
  const { t } = useTranslation();
  useHead(t("pages.payment.title"));
  const params = useParams();
  const [searchParams] = useSearchParams();
  const swishIdRef = searchParams.get("ref"); // comes from Swish callback
  const mockIdRef = searchParams.get("mockRef"); // comes from Swish callback
  const [mockPaid, setMockPaid] = useState(false);

  const { data: invoice } = useGetInvoiceQuery(params.id as string, {
    pollingInterval: 5000,
  });

  const [initSwishPayment] = useInitSwishPaymentMutation({
    fixedCacheKey: "swish-payment",
  });

  const onSwishClick = () => {
    if (!invoice) {
      console.error("No invoice found!");
      return;
    }

    const payload: SwishPaymentRequest = {
      invoiceId: params.id as string,
      payeePaymentReference: invoice.paymentReference as string,
      payeeAlias: "1233908639",
      amount: Number(invoice.totalAmount),
      currency: "SEK",
      callbackUrl: "https://localhost:3000/2/payment", // not used by backend but needed
      deviceType: isMobile ? "mobile" : "desktop",
      message: "test mctest, why is this message needed?",
    };

    initSwishPayment(payload);
  };

  // TODO: Add loading state
  if (!invoice) {
    return <></>;
  }

  if (mockPaid || invoice?.status.toLocaleLowerCase() === "paid") {
    return <PagePaymentStatus invoiceId={invoice.id} />;
  }

  if (mockIdRef) {
    sleep(2500).then(() => setMockPaid(true));
    return <PagePaymentStatus invoiceId={invoice.id} isLoading />;
  }

  if (swishIdRef) {
    return <PagePaymentStatus invoiceId={invoice.id} isLoading />;
  }

  return (
    <LayoutPage bgColor="transparent" paddingTop={120}>
      <div className={styles.content}>
        <PageHeader title={t("pages.payment.title")} />

        <div className={styles.paymentOptions}>
          <InvoicePaymentCard
            price={invoice?.totalAmount}
            currency={invoice?.currency}
            provider="swish"
            onOpen={onSwishClick}
            invoiceId={invoice.id}
          />
        </div>
      </div>
    </LayoutPage>
  );
}
