import { useTranslation } from "react-i18next";
import { Fragment } from "react/jsx-runtime";

import { Card } from "components/Card";
import { Text } from "components/Text";

import { formatCurrencyWithNumberFormat } from "lib/helpers";
import { InvoiceItem } from "lib/types";

import styles from "./InvoiceItems.module.scss";

type Props = {
  invoiceItems: InvoiceItem[];
  currency: string;
  vatAmount: string;
  language: string;
  reminderFee?: string;
};

export function InvoiceItems({
  invoiceItems,
  currency,
  vatAmount,
  language,
}: Props) {
  const { t } = useTranslation();

  const totalAmount = invoiceItems
    .map((item) => parseFloat(item.totalAmount))
    .reduce((acc, curr) => acc + curr, 0);

  return (
    <Card>
      <div className={styles.invoiceItems}>
        <Text as="h4" style="body">
          {t("features.invoice.details")}
        </Text>
        <>
          {invoiceItems.map((item) => (
            <Fragment key={item.id}>
              <div className={styles.item}>
                <Text as="p" style="body">
                  {item.name}
                </Text>
                <div className={styles.priceContainer}>
                  {item.quantity > 1 && (
                    <span className={styles.quantityInline}>
                      {item.quantity} x
                    </span>
                  )}
                  <Text as="p" style="body">
                    {formatCurrencyWithNumberFormat(
                      item.unitPrice,
                      language,
                      currency,
                      true,
                    )}
                  </Text>
                </div>
              </div>
              <div key={`${item.id}-qty`} className={styles.quantity}>
                <Text as="p" style="body" color="--content-muted">
                  {t("features.invoice.quantity-abbr")}: {item.quantity}
                </Text>
                {item.quantity > 1 && (
                  <Text as="p" style="body">
                    {formatCurrencyWithNumberFormat(
                      item.totalAmount,
                      language,
                      currency,
                      true,
                    )}
                  </Text>
                )}
              </div>
            </Fragment>
          ))}

          <hr className={styles.divider} />

          <div className={styles.item}>
            <Text as="p" style="body" color="--content-muted">
              {t("features.invoice.vatAmount")}
            </Text>
            <Text as="p" style="body">
              {formatCurrencyWithNumberFormat(
                vatAmount,
                language,
                currency,
                true,
              )}
            </Text>
          </div>
        </>

        <div className={styles.total}>
          <Text as="p" style="body">
            {t("features.invoice.total")}
          </Text>

          <Text as="p" style="body">
            {formatCurrencyWithNumberFormat(
              totalAmount,
              language,
              currency,
              true,
            )}
          </Text>
        </div>
      </div>
    </Card>
  );
}
