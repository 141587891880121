import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { InvoiceContainer } from "features/invoice";
import { LayoutPage } from "layouts/LayoutPage";

import { Button } from "components/Button";
import { PageHeader } from "components/PageHeader";
import { SkeletonGroup } from "components/SkeletonGroup/SkeletonGroup";
import { Tabs } from "components/Tabs";

import { classNames } from "lib/classNames";
import { useHead } from "lib/hooks/useHead";
import { useGetAllInvoicesQuery } from "lib/slices/invoiceServiceSlice";
import { InvoiceStatus } from "lib/types";

import styles from "./PageAllInvoices.module.scss";

export function PageAllInvoices() {
  const { t } = useTranslation();
  const [page, setPage] = useState(0);
  const [selectedTab, setSelectedTab] = useState(0);

  const {
    data: allInvoices,
    isFetching,
    refetch,
  } = useGetAllInvoicesQuery(page, {
    refetchOnMountOrArgChange: true,
  });

  const INDEX_TO_TITLE = [
    {
      title: t("pages.allInvoices.all"),
      tooltip: t("pages.allInvoices.tooltip.all"),
    },
    {
      title: t("pages.allInvoices.unpaid"),
      tooltip: t("pages.allInvoices.tooltip.unpaid"),
    },
    {
      title: t("pages.allInvoices.paid"),
      tooltip: t("pages.allInvoices.tooltip.paid"),
    },
  ];

  const paidInvoices = allInvoices?.data.filter(
    (invoice) => invoice.status === "paid",
  );
  const unpaidInvoices = allInvoices?.data.filter(
    (invoice) => invoice.status !== "paid",
  );

  useHead(
    t("pages.allInvoices.title", {
      state: INDEX_TO_TITLE[selectedTab].title,
    }),
  );

  const invoicesWrapperRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (invoicesWrapperRef.current) {
      invoicesWrapperRef.current.scrollTop = 0;

      refetch();
    }
  }, [selectedTab]);

  useEffect(() => {
    const onScroll = () => {
      const scrolledToBottom =
        invoicesWrapperRef.current!.scrollHeight -
          invoicesWrapperRef.current!.scrollTop <=
        invoicesWrapperRef.current!.clientHeight + 100;

      if (
        scrolledToBottom &&
        !isFetching &&
        allInvoices!.data.length < allInvoices!.totalItems
      ) {
        setPage(page + 1);
      }
    };

    invoicesWrapperRef.current?.addEventListener("scroll", onScroll);

    return function () {
      invoicesWrapperRef.current?.removeEventListener("scroll", onScroll);
    };
  }, [page, isFetching, allInvoices]);

  return (
    <LayoutPage bgColor="transparent" paddingTop={120}>
      <div className={styles.content}>
        <PageHeader
          title={t("pages.allInvoices.title", {
            state: INDEX_TO_TITLE[selectedTab].title,
          })}
          // tooltip={INDEX_TO_TITLE[selectedTab].tooltip}
        />

        <section>
          <Tabs
            onChange={(index) => setSelectedTab(index)}
            tabs={[
              {
                tooltipText: INDEX_TO_TITLE[0].tooltip,
                title: t("pages.allInvoices.all"),
                content: (
                  <section>
                    <div
                      className={classNames({
                        [styles.invoices]: true,
                      })}
                      ref={invoicesWrapperRef}
                    >
                      {allInvoices?.data.map((invoice, index) => (
                        <InvoiceContainer
                          key={index}
                          state={invoice.status as InvoiceStatus}
                          invoiceId={invoice.id}
                          displayId={invoice.invoiceNo}
                          date={invoice.date}
                          amount={invoice.totalAmount}
                          currency={invoice.currency}
                        />
                      ))}

                      {/* prettier-ignore */}
                      {(!allInvoices ||
                        (allInvoices &&
                          allInvoices.data.length < allInvoices.totalItems &&
                          page !== 0)) && (
                        <SkeletonGroup
                          numberOfRowsWithSkeletons={5}
                          options={[
                            {
                              parentJustifyContent: "space-between",
                              items: [
                                {
                                  width: "147px",
                                  height: "18px",
                                  borderRadius: "10px",
                                },
                                {
                                  gap: "16px",
                                  items: [
                                    {
                                      width: "175px",
                                      height: "18px",
                                      borderRadius: "10px",
                                    },
                                    {
                                      width: "116px",
                                      height: "18px",
                                      borderRadius: "10px",
                                    },
                                  ],
                                },
                              ],
                            },
                          ]}
                        />
                      )}
                    </div>
                  </section>
                ),
              },
              {
                tooltipText: INDEX_TO_TITLE[1].tooltip,
                title: t("pages.allInvoices.unpaid"),
                content: (
                  <section>
                    <div className={styles.invoices} ref={invoicesWrapperRef}>
                      {unpaidInvoices?.map((invoice, index) => (
                        <InvoiceContainer
                          key={index}
                          state={invoice.status as InvoiceStatus}
                          invoiceId={invoice.id}
                          displayId={invoice.invoiceNo}
                          date={invoice.date}
                          amount={invoice.totalAmount}
                          currency={invoice.currency}
                        />
                      ))}

                      {/* prettier-ignore */}
                      {(!allInvoices ||
                        !unpaidInvoices ||
                        (unpaidInvoices &&
                          unpaidInvoices.length < allInvoices.totalItems &&
                          page !== 0)) && (
                        <SkeletonGroup
                          numberOfRowsWithSkeletons={5}
                          options={[
                            {
                              parentJustifyContent: "space-between",
                              items: [
                                {
                                  width: "147px",
                                  height: "18px",
                                  borderRadius: "10px",
                                },
                                {
                                  gap: "16px",
                                  items: [
                                    {
                                      width: "175px",
                                      height: "18px",
                                      borderRadius: "10px",
                                    },
                                    {
                                      width: "116px",
                                      height: "18px",
                                      borderRadius: "10px",
                                    },
                                  ],
                                },
                              ],
                            },
                          ]}
                        />
                      )}
                    </div>
                    {/*
                    Story AS-736 to remove pay all buttons until we have a solution for it
                    So we will comment out the button for now
                    <Button
                      intent="primary"
                      text={t("pages.allInvoices.payAll")}
                      icon="card"
                    />*/}
                    {unpaidInvoices?.length === 1 && (
                      <div className={styles.oneUnpaidInvoice}>
                        <Link to={`/${unpaidInvoices[0].id}/payment`}>
                          <Button
                            text={t("features.invoice.pay", {
                              amount: unpaidInvoices[0].totalAmount,
                              currency: unpaidInvoices[0].currency,
                            })}
                            intent="primary"
                            icon="card"
                            onClick={() => undefined}
                          />
                        </Link>
                      </div>
                    )}
                  </section>
                ),
              },
              {
                tooltipText: INDEX_TO_TITLE[2].tooltip,
                title: t("pages.allInvoices.paid"),
                content: (
                  <section>
                    <div className={styles.invoices} ref={invoicesWrapperRef}>
                      {paidInvoices?.map((invoice, index) => (
                        <InvoiceContainer
                          key={index}
                          state={invoice.status as InvoiceStatus}
                          invoiceId={invoice.id}
                          displayId={invoice.invoiceNo}
                          date={invoice.date}
                          amount={invoice.totalAmount}
                          currency={invoice.currency}
                        />
                      ))}

                      {/* prettier-ignore */}
                      {(!allInvoices ||
                        !paidInvoices ||
                        (paidInvoices &&
                          paidInvoices.length < allInvoices?.totalItems &&
                          page !== 0)) && (
                        <SkeletonGroup
                          numberOfRowsWithSkeletons={5}
                          options={[
                            {
                              parentJustifyContent: "space-between",
                              items: [
                                {
                                  width: "147px",
                                  height: "18px",
                                  borderRadius: "10px",
                                },
                                {
                                  gap: "16px",
                                  items: [
                                    {
                                      width: "175px",
                                      height: "18px",
                                      borderRadius: "10px",
                                    },
                                    {
                                      width: "116px",
                                      height: "18px",
                                      borderRadius: "10px",
                                    },
                                  ],
                                },
                              ],
                            },
                          ]}
                        />
                      )}
                    </div>
                  </section>
                ),
              },
            ]}
          />
        </section>
      </div>
    </LayoutPage>
  );
}
